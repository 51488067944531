<template>
    <div class="fill-height">
        <v-row align="center" style="height:100%;" no-gutters>
            <v-col cols="8" class="background fill-height">
            </v-col>
            <v-col cols="4" >
                <v-card flat max-width="500" style="margin: 0 auto;">
                    <v-card-text>
                        <v-row>
                            <!-- Logo -->
                            <v-col cols="12" class="text-center">
                                <img :src="require('@/assets/urbi.png')" width="200px" />
                                <!-- <h2 class="text-center">LOGO</h2> -->
                            </v-col>

                            <v-col cols="12" class="mb-0 pb-0">
                                <v-alert dense text :type="alertType" v-model="alertShow">{{ alertMessage }}</v-alert>
                            </v-col>

                            <!-- Form -->
                            <v-col cols="12" class="py-0">
                                <div v-show="localView == 'login'">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field outlined placeholder="Email" v-model="email" :error="emailError" prepend-inner-icon="mdi-account" background-color="grey lighten-5" @keydown.enter="login"></v-text-field>
                                            <v-text-field outlined placeholder="Password" type="password" v-model="password" :error="passwordError" prepend-inner-icon="mdi-key" background-color="grey lighten-5" @keydown.enter="login"></v-text-field>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-btn block color="primary" depressed @click="login" :loading="loginIn">Login</v-btn>
                                        </v-col>

                                        <v-col cols="6" class="mx-0">
                                            <a href="#" style="text-decoration: none;" @click.prevent="localView = 'forgotPassword'">Forgot password?</a>
                                        </v-col>

                                        <v-col cols="6" class="mx-0 text-right">
                                            <!-- Need an account? <a :href="signUpUrl">Sign up now.</a> -->
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <div v-show="localView == 'forgotPassword'">
                                    <v-row>
                                        <v-col cols="12" class="mx-0 py-0">
                                            <!-- <h3 class="ma-0">Recover your password</h3> -->
                                            <v-card-title class="pl-0 pt-0">Recover your password</v-card-title>
                                            <v-card-subtitle class="pl-0">Enter your account email to recover your password</v-card-subtitle>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="12" class="mx-0 pb-0">
                                            <v-text-field type="email" v-model="recover.email" outlined placeholder="Email" background-color="grey lighten-5" @keydown.enter="recoverPassword" :error-messages="recover.emailError"></v-text-field>
                                        </v-col>
                                        <v-col :cols="12" class="mx-0 pb-0">
                                            <v-btn block  color="primary" depressed @click="recoverPassword" :loading="saving">{{ recover.buttonText }}</v-btn>
                                        </v-col>

                                        <v-col :cols="12" class="mx-0 pb-0">
                                            <!-- <v-btn text small color="primary" @click="localView = 'login'">&lt; Back</v-btn> -->
                                            <a href="#" style="text-decoration: none;" @click.prevent="localView = 'login'">&lt; Back</a>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <div v-show="localView == 'resetPassword'">
                                    <v-row>
                                        <v-col cols="12" class="mx-0 py-0">
                                            <!-- <h3 class="ma-0">Recover your password</h3> -->
                                            <v-card-title class="pl-0 pt-0">Reset your password</v-card-title>
                                            <v-card-subtitle class="pl-0">Enter a new password to access your account</v-card-subtitle>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="12" class="mx-0 pb-0">
                                            <change-password ref="pwd" @login="resetPassword"></change-password>
                                            <!-- <v-text-field outlined placeholder="New Password" type="password" v-model="reset.password" :error="reset.passwordError" background-color="grey lighten-5" @keydown.enter="resetPassword"></v-text-field>
                                            <v-text-field outlined placeholder="Confirm Password" type="password" v-model="reset.confirmation" :error="reset.confirmationError" :error-messages="reset.confirmationErrorText" background-color="grey lighten-5" @keydown.enter="resetPassword"></v-text-field> -->
                                        </v-col>
                                        <v-col :cols="12" class="mx-0 pb-0">
                                            <v-btn block  color="primary" depressed @click="resetPassword" :loading="saving">{{ reset.buttonText }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChangePassword from '@/components/ChangePassword.vue';

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: '',
            emailError: false,
            password: '',
            passwordError: false,

            loginIn: false,

            signUpUrl: '#',

            localView: '',
            recover: {
                email: '',
                emailError: '',
                buttonText: 'Recover password',
                errorMessage: '',
            },
            reset: {
                email: '',
                password: '',
                passwordError: false,
                confirmation: '',
                confirmationError: false,
                buttonText: 'Change password',
            },
            saving: false,

            alertShow: false,
            alertType: 'error',
            alertMessage: '',
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn'
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            signIn          : 'auth/login',
            forgotPassword  : 'auth/forgotPassword',
            changePassword  : 'auth/changePassword',
            authAction      : 'auth/authAction'
        }),
        init() {
            this.localView = 'login';

            if( this.isLoggedIn )
                this.$router.push('/dashboard');
        },
        async login() {
            this.alertShow = false;

            if( !this.isValid() )
                return;

            try {
                this.loginIn = true;
                let res = await this.signIn({
                    username: this.email,
                    password: this.password
                });
                this.loginIn = false;

                if( !res.status ) {
                    this.alertType = 'error';
                    this.alertMessage = res.message;
                    this.alertShow = true;
                    this.password = '';
                    return;
                }
                else if( res.pwdChange ) {
                    this.localView = 'resetPassword';
                    return;
                }

                // this.$store.dispatch( 'login', res.token );
            }
            catch( error ) {
                this.loginIn = false;
                this.alertType = 'error';
                this.alertMessage = error;
                this.alertShow = true;
            }
        },
        isValid() {
            let errorFound = false;
            this.emailError = this.passwordError = false;

            if( this.email.length == 0 ) {
                this.emailError = true;
                errorFound = true;
            }
            if( this.password.length == 0 ) {
                this.passwordError = true;
                errorFound = true;
            }

            return !errorFound;
        },
        isRecoverFormValid() {
            let errorFound = false;
            this.recover.emailError = '';

            if( this.recover.email.length == 0 ) {
                errorFound = true;
                this.recover.emailError = 'required';
            }

            return !errorFound;
        },
        async recoverPassword() {
            this.alertShow = false;

            if( !this.isRecoverFormValid() ) 
                return;

            try {
                this.saving = true;
                let res = await this.forgotPassword({
                    username: this.recover.email
                });
                this.saving = false;

                if( !res.data.status ) {
                    this.alertType = 'error';
                    this.alertMessage = res.data.message;
                    this.alertShow = true;
                    return;
                }

                this.alertType = 'success';
                this.alertMessage = res.data.message;
                this.alertShow = true;

                this.recover.email = '';
            }
            catch(error) {
                console.log(error);
                this.saving = false;
                this.alertType = 'error';
                this.alertMessage = error;
                this.alertShow = true;
            }
        },
        async resetPassword() {
            this.alertShow = false;
            
            if( !this.$refs.pwd.isFormValid() ) 
                return;

            try {
                this.saving = true;
                let res = await this.changePassword({
                    username    : this.email,
                    oldPassword : this.password,
                    newPassword : this.$refs.pwd.newPassword
                });
                this.saving = false;

                if( !res.data.status ) {
                    this.alertType = 'error';
                    this.alertMessage = res.data.message;
                    this.alertShow = true;
                    return;
                }

                this.alertType = 'success';
                this.alertMessage = res.data.message;
                this.alertShow = true;

                this.$refs.pwd.clear();

                localStorage.setItem('auth', res.data.token);
                await this.authAction();
                this.$router.push('/dashboard');
            }
            catch(error) {
                console.log(error);
                this.saving = false;
                this.alertType = 'error';
                this.alertMessage = error;
                this.alertShow = true;
            }
        },
    },
    components: {
        ChangePassword
    }
}
</script>

<style scoped>
.background {
    background: url('../assets/condominium.jpeg') no-repeat center center;
    background-size: cover;
}
</style>
